<template>
  <b-card>
    <b-input-group class="mb-2">
      <b-input-group-prepend>
        <b-button variant="primary" @click="list" :disabled="busy.list">
          <i class="fa fa-search"></i> 검색
          <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
        </b-button>
      </b-input-group-prepend>
      <b-form-input id="search" type="text" placeholder="" v-model="form.list.search" @keyup="filterList()" @keypress.enter="list()" autocomplete="off" v-focus></b-form-input>
    </b-input-group>

    <div class="grid">
      <div class="th text-center">#</div>
      <div class="th">쿼리명</div>
      <div class="th">Tags</div>
      <div class="th">파라미터</div>
      <div class="th">CRON</div>
      <div class="th">실행이력</div>
      <template v-for="q in items.page">
        <div class="td text-center">{{ q.no }}</div>
        <div class="td pointer bold" @click="selectScript(q)">{{ q.name }}</div>
        <div class="td">
          <b-badge variant="light" v-for="t in q.tags">{{ t }}</b-badge>
        </div>
        <div class="td">{{ q.params.length ? `${q.params.length} 개` : '' }}</div>
        <div class="td">{{ q.cron || '' }}</div>
        <div class="td">{{ q.elapsed && (q.elapsed.cnt === 50 ? '>= 50' : q.elapsed.cnt) || 0 }}</div>
      </template>
    </div>
    <b-pagination :total-rows="items.filteredList.length" :per-page="pageLimit" :limit="20" v-model="currentPage" prev-text="Prev" next-text="Next" @change="setPage"/>
    <hr/>

    <div class="clearfix">
      <div class="pull-right">
        <b-btn variant="info" class="mr-1" @click="$refs.importModal.showModal();">데이터 Import</b-btn>
        <b-btn :variant="favoriteCollapse ? 'warning' : 'outline-warning'" v-b-toggle.favorite>즐겨찾기</b-btn>
      </div>
      <b-btn v-if="$R('DEV')" variant="primary" class="mr-1" @click="newScript">새 스크립트</b-btn>
      <b-btn variant="warning" class="mr-1" @click="reset">초기화</b-btn>
    </div>




    <template v-if="charts">
      <b-row>
        <b-col v-for="(c, idx) in charts" v-if="
                  ['line', 'bar'].includes(c.type) && c.labelCol && (c.series === 'row' && c.seriesCols || c.series === 'col' && c.dataCols) ||
                  ['pie'].includes(c.type) && (c.series === 'row' && c.labelCol && c.dataCol || c.series === 'col' && c.dataCols)
                 "
               class="mb-3" :cols="c.cols || Math.max(6, 12 / charts.length)" :key="idx">
          <line-chart v-if="c.type === 'line'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></line-chart>
          <bar-chart v-if="c.type === 'bar'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></bar-chart>
          <pie-chart v-if="c.type === 'pie'" :chart-id="`chart${idx}`" :chartdata="c.data" :options="c.options" style="position: relative" :style="c.style || chartStyle"></pie-chart>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import LineChart from '@/views/charts/Line.vue'
import BarChart from '@/views/charts/Bar.vue'
import PieChart from '@/views/charts/Pie.vue'

export default {
  name: "DataDashboard",
  components: {LineChart, BarChart, PieChart},
  data() {
    return {
      form: {
        list: {
          search: '',
        }
      },
      busy: {list: false, run: false, save: false, remove: false, xlsx: false, csv: false, json: false},
      modal: {import: false},
      items: {list: [], filteredList: [], page: []},
      favorite: {},
      favoriteCollapse: true,
      noMap: {},
      tagCntMap: {},
      currentPage: 1,
      pageLimit: 10,
      obj: null,
      params: {},
      options: {
        tags: [],
        roles: [],
      },
      sheets: [],
      tabIndex: 0,

      charts: [],
      chartStyle: 'max-height: 300px',
      frameCallback: () => {},
    }
  },
  async created() {
    await this.list();

    const no = this.$route.params.no;
    if (no) {
      const obj = this.items.list.find(e => e.no === +no);
      if (obj) {
        this.selectScript(obj);
      }
    }

    // this.init().then();
  },
  methods: {
    async init() {
      // const chartMetas = [
      //   {no: 44, name: '일별 상품수 추이', class: '', style: '', cols: '4'},
      //   {no: 44, name: '일별 매출액 추이', class: '', style: '', cols: '4'},
      //   {no: 44, name: '주문수', class: '', style: '', cols: '4'},
      //   {no: 44, name: '비율', class: '', style: '', cols: '4'},
      //   {no: 44, name: '유입수', class: '', style: 'height: 300px', cols: '4', opts: {legend: false}},
      //   {no: 44, name: '결제기여금액', class: '', style: 'height: 300px', cols: '4', opts: {legend: false}},
      //   {no: 44, name: '판매분석', class: '', style: '', cols: '4'},
      // ];
      const chartMetas = [
        {no: 61, name: '일별 Users', cols: '4'},
        {no: 61, name: '일별 PV', cols: '4'},
        {no: 61, name: '일별 PV/Session', cols: '4'},
        {no: 61, name: '일별 주문수', cols: '4'},
        {no: 61, name: '일별 이탈률', cols: '4'},
        {no: 61, name: '일별 CVR', cols: '4'},
        {no: 61, name: '시간별 Users', cols: '4'},
        {no: 61, name: '시간별 PV', cols: '4'},
        {no: 61, name: '시간별 PV/Session', cols: '4'},
        {no: 61, name: '시간별 주문수', cols: '4'},
        {no: 61, name: '시간별 이탈률', cols: '4'},
        {no: 61, name: '시간별 CVR', cols: '4'},
      ];
      const no = this.$utils.set(chartMetas.map(e => e.no));
      const stores = await this.$api.getJson(`/data/store/loadScript?no=${no.join(',')}`);
      const results = await this.$api.getJson(`/data/store/lastResults?no=${no.join(',')}`);

      this.charts = [];
      if (stores && results) {
        const charts = [];
        const storeMap = this.$utils.arr2map(stores.list, 'no');
        chartMetas.forEach(cm => {
          const store = storeMap[cm.no];
          const sheets = results.result[cm.no]; // sheets
          const c = store.charts.find(e => e.name === cm.name);
          c.opts = Object.assign({}, c.opts, cm.opts);

          let sheet;
          if (!c.sheetIdx) sheet = sheets[0];
          else if (!isNaN(c.sheetIdx)) sheet = sheets[+c.sheetIdx];
          else sheet = sheets.find(e => e.name === c.sheetIdx);
          if (!sheet) {
            c.data = {labels: ['데이터없음'], datasets: [{label: '데이터없음', data: []}]};
            return;
          }
          this.assignChartData([c], sheet);

          if (cm.class) c.class = cm.class;
          if (cm.style) c.style = cm.style;
          if (cm.cols) c.cols = cm.cols;
          charts.push(c);
        });
        this.charts = charts;
      }
    },
    async list() {
      this.busy.list = true;
      // const j = await this.$api.getJson(`/data/store/list?search=${encodeURIComponent(this.form.list.search)}`);
      const j = await this.$api.getJson(`/data/store/list`);
      const fav = await this.$api.getJson('/data/store/favorite');
      this.busy.list = false;
      if (j) {
        j.list.forEach(e => {
          e.label = `${e.no}. ${e.name}`;
          e.tags = e.tags || [];
          e.elapsed_arr = (e._elapsed || []).sort((a, b) => a.elapsed - b.elapsed);
          if (e.elapsed_arr.length) {
            e.elapsed = {
              cnt: e.elapsed_arr.length,
              min: e.elapsed_arr[0].elapsed,
              avg: this.$utils.round(e.elapsed_arr.map(e => e.elapsed).reduce((a, b) => a + b, 0) / e.elapsed_arr.length, 3),
              max: e.elapsed_arr.slice(-1)[0].elapsed,
            }
          }
        });
        this.items.list = j.list;
        this.noMap = this.$utils.arr2map(j.list, 'no');
        this.tagCntMap = this.$utils.arr2multi(j.list.map(e => e.tags).flat());
        this.options.tags.sort((a, b) => this.tagCntMap[b] - this.tagCntMap[a]);
        this.filterList();
        this.setPage(1);
      }
      if (fav) {
        this.favorite = this.$utils.arr2map(fav.doc.no);
        j.list.forEach(e => {
          if (this.favorite[e.no]) this.favorite[e.no] = e;
        });
      }
    },
    filterList() {
      const s = this.form.list.search.trim();
      if (!s) {
        this.items.filteredList = this.items.list;
      } else {
        const re = new RegExp(s.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'); // escape regexp
        this.items.filteredList = this.items.list.filter(e => {
          return e.no === +s || re.test(e.name) || e.tags.some(t => re.test(t));
        });
      }
      this.setPage(this.currentPage);
    },
    setPage(n) {
      this.currentPage = n;
      this.items.page = this.items.filteredList.slice((n - 1) * this.pageLimit, n * this.pageLimit);
    },
    newScript() {
      this.obj = {name: 'Sample Script', script: `my.godo.query('select 1')`, params: [], tags: [], roles: [], charts: []};
      this.params = {};
      this.sheets = [{rows: [{}], keys: ['data']}];
      this.setHotTable();
    },
    reset() {
      this.form.list.search = '';
      this.list();
      this.obj = null;
    },
    makeFields(data) {
      const dupRow = {};
      data.forEach(row => Object.assign(dupRow, row));
      return Object.keys(dupRow);
    },
    async selectScript(obj) {
      this.obj = obj;
      if (obj) {
        window.storeObj = obj;
        history.replaceState(null, null, location.origin + '/#/data/store/' + obj.no);
        this.params = {};
        obj.params && obj.params.forEach(e => {
          if (['string', 'select', 'json'].includes(e.type)) this.params[e.name] = e.default || '';
          else if (e.type === 'number') this.params[e.name] = e.default ? +e.default : '';
          else if (e.type === 'date') this.params[e.name] = '';
          else if (e.type.endsWith('preset')) this.params[e.name] = [];
          else this.params[e.name] = null;
        });
        obj.charts.forEach(c => {
          if (!c.opts) c.opts = {};
        });

        this.sheets = [];
        const sheets = await this.loadLastResult(obj.no);
        if (sheets) {
          this.sheets = sheets;
          this.setHotTable();
          if (obj.charts.length) this.assignChartData();
        }
      }
    },

    assignChartData(charts, sheet) {
      // this.obj.charts, rows, keys || this.makeFields(rows)
      // 차트별로 데이터를 채워넣는다.
      for (const c of charts) { // c = {sheetIdx: '', type: '', series: 'row', labelCol: '', seriesCols: '', opts, ...}
        let rows = sheet.rows;
        if (c.filter) {
          const f = eval(`row => ${c.filter}`);
          rows = rows.filter(f);
        }

        const opts = c.opts || {};
        const chartOptions = {
          ...(c.name ? {title: {display: true, text: c.name}} : {}),
          ...(opts.legend != null ? {legend: {display: opts.legend}} : {}),
        };

        if (['line', 'bar'].includes(c.type)) {
          const stack = c.type === 'bar' && opts.stack;
          const beginAtZero = opts.zero;
          if (c.series === 'row') {
            const labels = rows.map(row => row[c.labelCol]);
            const series = c.seriesCols.split(',').map(e => e.trim());
            const axes = Array.from(new Set(series.map(e => e.split(':')[1] || 'A')));
            const datasets = series.map(e => {
              const [label, axis] = e.split(':');
              return {
                label: label,
                ...(axes.length > 1 ? {yAxisID: axis || axes[0]} : {}),
                data: rows.map(row => row[label]),
                borderWidth: 2,
                ...(opts.radius != null ? {radius: opts.radius} : {}),
                fill: false,
              };
            });
            c.data = {labels, datasets};
            c.options = {
              ...chartOptions,
              scales: {
                ...(stack ? {xAxes: [{stacked: true}]} : {}),
                yAxes: axes.map((a, i) => ({
                  id: a,
                  type: 'linear',
                  position: i ? 'right' : 'left',
                  stacked: stack,
                  ...(beginAtZero ? {ticks: {beginAtZero}} : {}),
                  gridLines: {display: i === 0}
                }))
              }
            }
          } else if (c.series === 'col') {
            let keys = sheet.keys || this.makeFields(rows);
            if (c.dataCols === 'include') {
              keys = c.includeCols.split(',');
            } else if (c.dataCols === 'exclude') {
              const excludeCols = c.excludeCols ? c.excludeCols.split(',') : [];
              keys = keys.filter(e => !excludeCols.includes(e));
            }
            keys = keys.filter(e => e !== c.labelCol);
            const datasets = rows.map(e => ({
              label: e[c.labelCol],
              // backgroundColor: '#f87979',
              data: keys.map(l => e[l]),
              borderWidth: 2,
              ...(opts.radius != null ? {radius: opts.radius} : {}),
              fill: false,
            }));
            c.data = {labels: keys, datasets};
            c.options = {
              ...chartOptions,
              scales: {
                yAxes: [{
                  ticks: {
                    ...(beginAtZero ? {ticks: {beginAtZero}} : {}),
                  }
                }]
              },
            };
          }

          c.options.scales.yAxes.forEach(e => {
            e.ticks = e.ticks || {};
            Object.assign(e.ticks, {
              userCallback(value) {
                return value.toLocaleString();   // this is all we need
              },
            });
          });
        } else if (c.type === 'pie') {
          if (c.series === 'row') {
            c.data = {labels: rows.map(e => e[c.labelCol]), datasets: [{data: rows.map(e => e[c.dataCol])}]};
          } else if (c.series === 'col') {
            // 첫 번째 row 만 사용한다. 여러 row 가 있다면 filter 가 지정되어야 한다.
            const row = rows[0];
            let keys = Object.keys(row);
            if (c.dataCols === 'include') {
              keys = c.includeCols.split(',');
            } else if (c.dataCols === 'exclude') {
              const excludeCols = c.excludeCols ? c.excludeCols.split(',') : [];
              keys = keys.filter(e => !excludeCols.includes(e));
            }
            c.data = {labels: keys, datasets: [{data: keys.map(e => row[e])}]};
            c.options = {
              ...chartOptions,
            };
          }
          if (opts.sort) {
            // c.data.datasets[0].data.sort((a, b) => opts.sort === 'asc' ? a - b : b - a);
            const labels = c.data.labels;
            const data = c.data.datasets[0].data;
            const pair = labels.map((e, i) => [e, data[i]]);
            pair.sort((a, b) => opts.sort === 'asc' ? a[1] - b[1] : b[1] - a[1]);
            c.data.labels = pair.map(e => e[0]);
            c.data.datasets[0].data = pair.map(e => e[1]);
          }
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
